import React from "react";
import axios from "axios";
import { Button} from "reactstrap";
import DateEditor from "react-tabulator/lib/editors/DateEditor";
import MultiValueFormatter from "react-tabulator/lib/formatters/MultiValueFormatter";
// import MultiSelectEditor from "react-tabulator/lib/editors/MultiSelectEditor";

import "react-tabulator/lib/styles.css"; // default theme
import "react-tabulator/css/bootstrap/tabulator_bootstrap.min.css"; // use Theme(s)

import { ReactTabulator, reactFormatter } from "react-tabulator"; 

const editableColumns = [
  {title: "Ref#",field:"contact_id",  align:"center",formatter:"rownum"},
  {
    title: "Company",
    field: "company",
    width: 150,
    editor: "input",
    // headerFilter: "input"
  },
  {
    title: "Contact Name",
    field: "contact_name",
    width: 150,
    editor: "input",
    // headerFilter: "input"
  },
  {
    title: "Role",
    field: "role",
    // width: 150,
    editor: "input",
    // headerFilter: "input"
  },
  {
    title: "Contact Phone",
    field: "contact_phone",
    width: 150,
    editor: "input",
  },
  {
    title: "Contact Email",
    field: "email",
    width: 150,
    editor: "input",
  },
  {
    title: "Notes",
    field: "notes",
    editor: "input",
  },
  {
    title: "Delete Contact",
    field: "delete_contact",
    formatter: "buttonCross",
    align:"center",
    cellClick: (e,cell)=>{
      cell.getRow().delete();
      var contact = cell.getRow().getData();
      axios({
        method:"POST",
        url:"http://backend.pivotshot.com/deleteContact",
        headers:{
          jwttoken: localStorage.getItem("jwt-token")
        },
        data:{
          contact_id:contact["contact_id"]
        }
      }).then((res)=>{
        var response = res.data;
        console.log(response);
      });
    }
  }
];

class ContactEditableTable extends React.Component {
  state = {
    contacts: [],
    length:0,
  };
  constructor(props){
    super(props);
    this.onAddRow= this.onAddRow.bind(this);
  }

  ref = null;

  componentDidMount(){
    axios({
      method:"GET",
      url:"http://backend.pivotshot.com/getContacts",
      headers:{
        jwttoken: localStorage.getItem("jwt-token")
      },
    }).then((res)=>{
      var response = res.data;
      if(response.contacts.length>0){
        // alert(response.ri.length);
        this.setState({length:response.contacts.length});
        this.setState({contacts:response.contacts});
      }
    });
  }
  onAddRow(){
    var date = new Date();
    this.ref.table.addRow({
        company: "Add Company",
        contact_name: "Add Contact Name",
        role: "Add Role",
        contact_phone: "Add Contact Phone",
        email: "Add Email",
        notes: "Add Notes",
    //   target_date: ((date.getMonth() > 8) ? (date.getMonth() + 1) : ('0' + (date.getMonth() + 1))) + '/' + ((date.getDate() > 9) ? date.getDate() : ('0' + date.getDate())) + '/' + date.getFullYear(),
    }); 
    this.setState({length:this.state.length+1});
    console.log(this.state.length);
  }

  cellEdit = (cell,e)=>{
    var contact = cell.getRow().getData();
    axios({
      method:"POST",
      url:"http://backend.pivotshot.com/addContact",
      headers:{
        jwttoken: localStorage.getItem("jwt-token")
      },
      data:{
        contact:contact
      }
    }).then((res)=>{
      var response = res.data;
      console.log(response);
      if(typeof contact["contact_id"]=='undefined')
      {
        contact["contact_id"]=response.contact_id;
        cell.getRow().update({"contact_id":response.contact_id});
      }
    });
  }

  render() {
    const options = {
      movableRows: true,
      movableColumns: true
    };
    return (
      <div>
        <Button
          className="btn-icon btn-3 ml-1 mb-2"
          color="primary"
          type="button"
          onClick={this.onAddRow}
        >
          <span className="btn-inner--icon mr-1">
            <i className="fa fa-plus" />
          </span>
          <span className="btn-inner--text">Add Row</span>
      </Button>
        <ReactTabulator
          ref={(ref) => (this.ref = ref)}
          columns={editableColumns}
          data={this.state.contacts}
          cellEdited={this.cellEdit}
          // rowClick={this.rowClick}
          options={options}
          footerElement={<span>Footer</span>}
        />
      </div>
    );
  }
}

export default ContactEditableTable;
