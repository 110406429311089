import React from "react";
import axios from "axios";
import { Button} from "reactstrap";
import DateEditor from "react-tabulator/lib/editors/DateEditor";
import moment from "moment";
import MultiValueFormatter from "react-tabulator/lib/formatters/MultiValueFormatter";
// import MultiSelectEditor from "react-tabulator/lib/editors/MultiSelectEditor";

import "react-tabulator/lib/styles.css"; // default theme
import "react-tabulator/css/bootstrap/tabulator_bootstrap.min.css"; // use Theme(s)

import { ReactTabulator, reactFormatter } from "react-tabulator"; 

var dateFormatter = function(cell, formatterParams){
    var value = cell.getValue();

    if(value){
        value = moment(value).format("MM/DD/YYYY");
    }

    return value;
}


class LeadEditableTable extends React.Component {
  state = {
    leads: [],
    length:0,
    editableColumns : [
      {title: "Ref#",field:"lead_id",align:"center",formatter:"rownum"},
      {
        title: "Client",
        field: "client",
        editor: "input"
      },
      {
        title: "Owner",
        field: "owner",
        width: 150,
        editor: "input",
        // headerFilter: "input"
      },
      {
        title: "Deal Size",
        field: "deal_size",
        width: 75,
        editor: "number",
        // headerFilter: "input"
      },
      {
        title: "Follow Up Date",
        field: "follow_up_date",
        sorter: "date",
        formatter:dateFormatter,
        editor: DateEditor,
        editorParams: { format: "MM/DD/YYYY" }
      },
      {
        title: "Last Encounter",
        field: "last_encounter",
        sorter: "date",
        formatter:dateFormatter,
        editor: DateEditor,
        editorParams: { format: "MM/DD/YYYY" }
      },
      {
        title: "Delete Lead",
        field: "delete_lead",
        formatter: "buttonCross",
        align:"center",
        cellClick: (e,cell)=>{
          cell.getRow().delete();   
          var lead = cell.getRow().getData();
          axios({
            method:"POST",
            url:"http://backend.pivotshot.com/deleteLead",
            headers:{
              jwttoken: localStorage.getItem("jwt-token")
            },
            data:{
              lead_id:lead["lead_id"]
            }
          }).then((res)=>{
            var response = res.data;
            console.log(response);
          });
        }
      }
    ]
  };
  constructor(props){
    super(props);
    this.onAddRow= this.onAddRow.bind(this);
  }
  ref = null;
  componentDidMount(){
    axios({
      method:"GET",
      url:"http://backend.pivotshot.com/getLeads",
      headers:{
        jwttoken: localStorage.getItem("jwt-token")
      },
    }).then((res)=>{
      var response = res.data;
      if(response.leads.length>0){
        // alert(response.ri.length);
        this.setState({length:response.leads.length});
        this.setState({leads:response.leads});
      }
    });
  }
  onAddRow(){
    var date = new Date();
    this.ref.table.addRow({
      client: "Add Client",
      owner: "Add Owner",
      deal_size: "Add Deal Size",
      follow_up_date: ((date.getMonth() > 8) ? (date.getMonth() + 1) : ('0' + (date.getMonth() + 1))) + '/' + ((date.getDate() > 9) ? date.getDate() : ('0' + date.getDate())) + '/' + date.getFullYear(),
      last_encounter: ((date.getMonth() > 8) ? (date.getMonth() + 1) : ('0' + (date.getMonth() + 1))) + '/' + ((date.getDate() > 9) ? date.getDate() : ('0' + date.getDate())) + '/' + date.getFullYear()
    }); 
    this.setState({length:this.state.length+1});
    console.log(this.state.length);

    //true adds to top
    // alert();
  }

  rowClick = (e, row) => {
    console.log("ref table: ", this.ref.table); // this is the Tabulator table instance
    console.log(`rowClick id: ${row.getData().id}`, row, e);
    console.log(row.getData());
    this.setState({ selectedName: row.getData().name });
    alert(this.state.selectedName);
  };

  cellEdit = (cell,e)=>{
    var lead = cell.getRow().getData();
    axios({
      method:"POST",
      url:"http://backend.pivotshot.com/addLead",
      headers:{
        jwttoken: localStorage.getItem("jwt-token")
      },
      data:{
        lead:lead
      }
    }).then((res)=>{
      var response = res.data;
      console.log(response);
      if(typeof lead["lead_id"]=='undefined')
      {
        lead["lead_id"]=response.lead_id;
        cell.getRow().update({"lead_id":response.lead_id});
      }
    });
  }

  render() {
    const options = {
      // height: 150,
      movableRows: true,
      movableColumns: true
    };
    return (
      <div>
        <Button
          className="btn-icon btn-3 ml-1 mb-2"
          color="primary"
          type="button"
          onClick={this.onAddRow}
        >
          <span className="btn-inner--icon mr-1">
            <i className="fa fa-plus" />
          </span>
          <span className="btn-inner--text">Add Row</span>
      </Button>
        <ReactTabulator
          ref={(ref) => (this.ref = ref)}
          columns={this.state.editableColumns}
          data={this.state.leads}
          cellEdited={this.cellEdit}
          // rowClick={this.rowClick}
          options={options}
          footerElement={<span>Footer</span>}
        />

        {/* <p>
          <a href="https://github.com/ngduc/react-tabulator" target="_blank">
            Back to: Github Repo: react-tabulator
          </a>
        </p>
        <p>
          <a
            href="https://github.com/ngduc/react-tabulator/blob/master/docs/examples.md"
            target="_blank"
          >
            More Codesandbox Examples
          </a>
        </p>
        <p>
          <a href="http://tabulator.info/examples/4.0" target="_blank">
            More Tabulator's Examples
          </a>
        </p> */}
      </div>
    );
  }
}

export default LeadEditableTable;
