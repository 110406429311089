import * as React from 'react';
import { GridComponent, ColumnsDirective, ColumnDirective, Inject, Page, Edit, Toolbar } from '@syncfusion/ej2-react-grids';
import { orderDataSource } from './data';
import { SampleBase } from './sample-base';
import { PropertyPane } from './property-pane';
import { DropDownListComponent } from '@syncfusion/ej2-react-dropdowns';
export class NormalEdit extends SampleBase {
    constructor() {
        super(...arguments);
        this.toolbarOptions = ['Add', 'Edit', 'Delete', 'Update', 'Cancel'];
        this.editSettings = { allowEditing: true, allowAdding: true, allowDeleting: true, newRowPosition: 'Top' };
        this.editparams = { params: { popupHeight: '300px' } };
        this.validationRule = { required: true };
        this.orderidRules = { required: true, number: true };
        this.pageSettings = { pageCount: 5 };
        this.format = { type: 'dateTime', format: 'M/d/y hh:mm a' };
        this.droplist = [
            { text: 'Top', value: 'Top' },
            { text: 'Bottom', value: 'Bottom' }
        ];
    }
    actionBegin(args) {
        if (args.requestType === 'save') {
            if (this.gridInstance.pageSettings.currentPage !== 1 && this.gridInstance.editSettings.newRowPosition === 'Top') {
                args.index = (this.gridInstance.pageSettings.currentPage * this.gridInstance.pageSettings.pageSize) - this.gridInstance.pageSettings.pageSize;
            }
            else if (this.gridInstance.editSettings.newRowPosition === 'Bottom') {
                args.index = (this.gridInstance.pageSettings.currentPage * this.gridInstance.pageSettings.pageSize) - 1;
            }
        }
    }
    ddChange() {
        this.gridInstance.editSettings.newRowPosition = this.dropDownInstance.value;
    }
    render() {
        return (<div className='control-pane mt-3'>
        <div className='control-section'>
          <div className='col-md-12 col-xl-12 col-lg-12'>
            <GridComponent dataSource={orderDataSource} ref={grid => this.gridInstance = grid} toolbar={this.toolbarOptions} allowPaging={true} editSettings={this.editSettings} pageSettings={this.pageSettings} actionBegin={this.actionBegin.bind(this)}>
              <ColumnsDirective>
                <ColumnDirective field='OrderID' headerText='Order ID' width='140' textAlign='Right' validationRules={this.orderidRules} isPrimaryKey={true}></ColumnDirective>
                <ColumnDirective field='CustomerName' headerText='Customer Name' width='150' validationRules={this.validationRule}></ColumnDirective>
                <ColumnDirective field='Freight' headerText='Freight' width='140' format='C2' textAlign='Right' editType='numericedit'></ColumnDirective>
                <ColumnDirective field='OrderDate' headerText='Order Date' editType='datetimepickeredit' format={this.format} width='160'></ColumnDirective>
                <ColumnDirective field='ShipCountry' headerText='Ship Country' width='150' editType='dropdownedit' edit={this.editparams}></ColumnDirective>
              </ColumnsDirective>
              <Inject services={[Page, Toolbar, Edit]}/>
            </GridComponent>
          </div>
      </div>
      </div>);
    }
}