import * as React from 'react';

import { Field } from '@progress/kendo-react-form';

import {
    FormInput, FormUpload,FormDateInput,FormDropDownList,FormDateRangePicker
} from './form-components.jsx';

import {
    nameValidator, passwordValidator,requiredValidator
} from './validators.jsx'

export const PersonnelDetails = (
    <div style={{ width: 480 }}>
        <Field
            key={'projectType'}
            id={'projectType'}
            name={'projectType'}
            label={'Project Type'}
            hint={'Enter the Project Type.'}
            component={FormDropDownList}
            data={["Traditional","Agile"]}
            defaultValue={"Traditional"}
        />
         {/* <Field
            key={'projectLead'}
            id={'projectLead'}
            name={'projectLead'}
            label={'Project Lead'}
            hint={'Enter the Project Lead.'}
            component={FormInput}
            validator={nameValidator}
        /> */}
        <Field
            key={'startDate'}
            id={'startDate'}
            name={'startDate'}
            component={FormDateRangePicker}
            validator={requiredValidator}
        />
        {/* <Field
            key={'endDate'}
            id={'endDate'}
            name={'endDate'}
            label={'End Date'}
            component={FormDateInput}
            validator={requiredValidator}
        /> */}
         {/* <Field
            key={'password'}
            id={'password'}
            name={'password'}
            label={'Password'}
            type={'password'}
            component={FormInput}
            validator={passwordValidator}
        /> */}
        {/* <Field
            key={'avatar'}
            id={'avatar'}
            name={'avatar'}
            label={'Avatar'}
            optional={true}
            hint={'Hint: Upload your avatar picture'}
            component={FormUpload}
        /> */}
    </div>
);