import React from "react";
import axios from "axios";
import { Button} from "reactstrap";
import DateEditor from "react-tabulator/lib/editors/DateEditor";
import MultiValueFormatter from "react-tabulator/lib/formatters/MultiValueFormatter";
// import MultiSelectEditor from "react-tabulator/lib/editors/MultiSelectEditor";

import "react-tabulator/lib/styles.css"; // default theme
import "react-tabulator/css/bootstrap/tabulator_bootstrap.min.css"; // use Theme(s)

import { ReactTabulator, reactFormatter } from "react-tabulator"; 

function SimpleButton(props) {
  const rowData = props.cell._cell.row.data;
  const cellValue = props.cell._cell.value || "Edit | Show";
  return <button onClick={() => alert(rowData.name)}>{cellValue}</button>;
}

const data = [
  
];

// Editable Example:
const RIOptions = {
  R: "R",
  I: "I"
};
const petOptions = [
  { id: "cat", name: "cat" },
  { id: "dog", name: "dog" },
  { id: "fish", name: "fish" }
];


class EditableTableTabulator extends React.Component {
  state = {
    ri: [],
    length:0,
    selectedName: "",
    editableColumns : [
      {title: "Ref#",field:"risk_id",align:"center",formatter:"rownum"},
      {
        title: "R/I",
        field: "ri",
        editor: "select",
        editorParams: {
          allowEmpty: true,
          showListOnEmpty: true,
          values: RIOptions
        },
        headerFilter: "select",
        headerFilterParams: { values: RIOptions }
      },
      {
        title: "Description",
        field: "desc",
        width: 150,
        editor: "input",
        // headerFilter: "input"
      },
      {
        title: "Action Taken to Resolve",
        field: "action",
        width: 150,
        editor: "input",
        // headerFilter: "input"
      },
      {
        title: "Owner",
        field: "owner",
        // width: 150,
        editor: "input",
        // headerFilter: "input"
      },
      {
        title: "Name if Exec Support Req'd",
        field: "exec_support",
        width: 150,
        editor: "input",
        // headerFilter: "input"
      },
      // {
      //   title: "Age",
      //   field: "age",
      //   hozAlign: "left",
      //   formatter: "progress",
      //   editor: "progress"
      // },
      {
        title: "Target Date",
        field: "target_date",
        sorter: "date",
        editor: DateEditor,
        editorParams: { format: "MM/DD/YYYY" }
      },
      {
        title: "Delete Risk/Issue",
        field: "delete_ri",
        formatter: "buttonCross",
        align:"center",
        cellClick: (e,cell)=>{
          cell.getRow().delete();   
          var ri = cell.getRow().getData();
          axios({
            method:"POST",
            url:"http://backend.pivotshot.com/deleteRI",
            headers:{
              jwttoken: localStorage.getItem("jwt-token")
            },
            data:{
              risk_id:ri["risk_id"]
            }
          }).then((res)=>{
            var response = res.data;
            console.log(response);
          });
        }
      }
      // {
      //   title: "Pets",
      //   field: "pets",
      //   sorter: (a, b) => a.toString().localeCompare(b.toString()),
      //   // editor: MultiSelectEditor,
      //   editorParams: { values: petOptions },
      //   formatter: MultiValueFormatter,
      //   formatterParams: { style: "PILL" }
      // },
      // {
      //   title: "Passed?",
      //   field: "passed",
      //   hozAlign: "center",
      //   formatter: "tickCross",
      //   editor: true
      // }
    ]
  };
  constructor(props){
    super(props);
    this.onAddRow= this.onAddRow.bind(this);
  }
  ref = null;

  
  // columns = [
  //   { title: "Name", field: "name", width: 150 },
  //   { title: "Age", field: "age", hozAlign: "left", formatter: "progress" },
  //   { title: "Favourite Color", field: "color" },
  //   { title: "Date Of Birth", field: "dob" },
  //   { title: "Rating", field: "rating", hozAlign: "center", formatter: "star" },
  //   {
  //     title: "Passed?",
  //     field: "passed",
  //     hozAlign: "center",
  //     formatter: "tickCross"
  //   },
  //   {
  //     title: "Custom",
  //     field: "custom",
  //     hozAlign: "center",
  //     editor: "input",
  //     formatter: reactFormatter(
  //       <SimpleButton
  //         onSelect={(name) => {
  //           this.setState({ selectedName: name });
  //           alert(name);
  //         }}
  //       />
  //     )
  //   }
  // ];
  componentDidMount(){
    axios({
      method:"GET",
      url:"http://backend.pivotshot.com/getRI",
      headers:{
        jwttoken: localStorage.getItem("jwt-token")
      },
    }).then((res)=>{
      var response = res.data;
      if(response.ri.length>0){
        // alert(response.ri.length);
        this.setState({length:response.ri.length});
        this.setState({ri:response.ri});
      }
    });
  }
  onAddRow(){
    var date = new Date();
    this.ref.table.addRow({
      ri: "Add R/I",
      desc: "Add Description",
      action: "Add Action",
      owner: "Add Owner",
      exec_support: "Add Executive Support",
      target_date: ((date.getMonth() > 8) ? (date.getMonth() + 1) : ('0' + (date.getMonth() + 1))) + '/' + ((date.getDate() > 9) ? date.getDate() : ('0' + date.getDate())) + '/' + date.getFullYear(),
    }); 
    this.setState({length:this.state.length+1});
    console.log(this.state.length);

    //true adds to top
    // alert();
  }

  rowClick = (e, row) => {
    console.log("ref table: ", this.ref.table); // this is the Tabulator table instance
    console.log(`rowClick id: ${row.getData().id}`, row, e);
    console.log(row.getData());
    this.setState({ selectedName: row.getData().name });
    alert(this.state.selectedName);
  };

  cellEdit = (cell,e)=>{
    var ri = cell.getRow().getData();
    axios({
      method:"POST",
      url:"http://backend.pivotshot.com/addRI",
      headers:{
        jwttoken: localStorage.getItem("jwt-token")
      },
      data:{
        ri:ri
      }
    }).then((res)=>{
      var response = res.data;
      console.log(response);
      if(typeof ri["risk_id"]=='undefined')
      {
        ri["risk_id"]=response.risk_id;
        cell.getRow().update({"risk_id":response.risk_id});
      }
    });
  }

  setData = () => {
    this.setState({ data });
  };

  clearData = () => {
    this.setState({ data: [] });
  };

  render() {
    const options = {
      // height: 150,
      movableRows: true,
      movableColumns: true
    };
    return (
      <div>
        {/* <ReactTabulator
          ref={(ref) => (this.ref = ref)}
          columns={this.columns}
          data={data}
          rowClick={this.rowClick}
          options={options}
          data-custom-attr="test-custom-attribute"
          className="custom-css-class"
        /> */}
        {/* <i>
          Selected Name: <strong>{this.state.selectedName}</strong>
        </i> */}

        {/* <h3>
          Asynchronous data: (e.g. fetch) -{" "}
          <button onClick={this.setData}>Set Data</button>
          <button onClick={this.clearData}>Clear</button>
        </h3> */}
        {/* <ReactTabulator columns={this.columns} data={this.state.data} /> */}

        {/* <h3>Editable Table</h3> */}
        <Button
          className="btn-icon btn-3 ml-1 mb-2"
          color="primary"
          type="button"
          onClick={this.onAddRow}
        >
          <span className="btn-inner--icon mr-1">
            <i className="fa fa-plus" />
          </span>
          <span className="btn-inner--text">Add Row</span>
      </Button>
        <ReactTabulator
          ref={(ref) => (this.ref = ref)}
          columns={this.state.editableColumns}
          data={this.state.ri}
          cellEdited={this.cellEdit}
          // rowClick={this.rowClick}
          options={options}
          footerElement={<span>Footer</span>}
        />

        {/* <p>
          <a href="https://github.com/ngduc/react-tabulator" target="_blank">
            Back to: Github Repo: react-tabulator
          </a>
        </p>
        <p>
          <a
            href="https://github.com/ngduc/react-tabulator/blob/master/docs/examples.md"
            target="_blank"
          >
            More Codesandbox Examples
          </a>
        </p>
        <p>
          <a href="http://tabulator.info/examples/4.0" target="_blank">
            More Tabulator's Examples
          </a>
        </p> */}
      </div>
    );
  }
}

export default EditableTableTabulator;
