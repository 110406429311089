import * as React from 'react';
import axios from "axios";

import { Field } from '@progress/kendo-react-form';

import {
    FormInput, FormUpload,FormDatePicker,FormDropDownListManager,FormDropDownListLead
} from './form-components.jsx';

import {
    nameValidator, passwordValidator,requiredValidator
} from './validators.jsx'


export const PersonnelDetails = (
    <div style={{ width: "50%" , margin:"0 auto"}}>
        <Field
            key={'projectManager'}
            id={'projectManager'}
            name={'proj_manager'}
            label={'Project Manager'}
            hint={'Enter the Project Manager.'}
            component={FormDropDownListManager}
            validator={nameValidator}
        />
         <Field
            key={'projectLead'}
            id={'projectLead'}
            name={'proj_lead'}
            label={'Project Lead'}
            hint={'Enter the Project Lead.'}
            component={FormDropDownListLead}
            validator={nameValidator}
        />
        <Field
            key={'projectEnd'}
            id={'projectEnd'}
            name={'proj_end'}
            label={'Project End'}
            component={FormDatePicker}
            validator={requiredValidator}
        />
        <Field
            key={'weekEnding'}
            id={'weekEnding'}
            name={'week_ending'}
            label={'Week Ending'}
            component={FormDatePicker}
            validator={requiredValidator}
        />
         {/* <Field
            key={'password'}
            id={'password'}
            name={'password'}
            label={'Password'}
            type={'password'}
            component={FormInput}
            validator={passwordValidator}
        /> */}
        {/* <Field
            key={'avatar'}
            id={'avatar'}
            name={'avatar'}
            label={'Avatar'}
            optional={true}
            hint={'Hint: Upload your avatar picture'}
            component={FormUpload}
        /> */}
    </div>
);