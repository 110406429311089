import * as React from 'react';
import axios from "axios";

import { Form, FormElement } from '@progress/kendo-react-form';
import { Button } from '@progress/kendo-react-buttons';
import { Stepper } from '@progress/kendo-react-layout';

import { PersonnelDetails } from './personnel-details.jsx';
import { PersonalDetails } from './personal-details.jsx';
import { PaymentDetails } from './payment-details.jsx';
import {KeyDeliverables} from './key-deliverables.jsx';

const stepPages = [
    PersonnelDetails,
    PersonalDetails,
    KeyDeliverables
];

export const StepForm = () => {
    const [step, setStep] = React.useState(0);
    const [formState, setFormState] = React.useState({});
    const [steps, setSteps] = React.useState([
        { label: 'Personnel Details', isValid: undefined },
        { label: 'Project Details', isValid: undefined },
        { label: 'Key Delivarables', isValid: undefined }
    ]);

    const lastStepIndex = steps.length - 1;
    const isLastStep = lastStepIndex === step;

    const onStepSubmit = React.useCallback(
        (event) => {
            const { isValid, values } = event;

            const currentSteps = steps.map((currentStep, index) => ({
                ...currentStep,
                isValid: index === step ? isValid : currentStep.isValid
            }));

            setSteps(currentSteps);

            if (!isValid) {
                return;
            }

            setStep(() => Math.min(step + 1, lastStepIndex));
            setFormState(values);

            if (isLastStep) {
                // alert(JSON.stringify(values));
                console.log(values);
                axios({
                    method:"POST",
                    url:"http://backend.pivotshot.com/createExecStatusReport",
                    headers:{
                        jwttoken: localStorage.getItem("jwt-token")
                    },
                    data:{
                        execStatusReport: values
                    }
                  }).catch((error)=>{
                    console.log(error);
                  }).then((res)=>{
                    var response = res.data;
                    // console.log(response);
                    if(response.code==400){
                      alert('Executive Status Report Could not be created!');
                    }
                    else{
                        alert(response.success);
                        window.location.reload();
                    }
                  });
            }
        },
        [step, steps, setSteps, setStep, setFormState,lastStepIndex, isLastStep]
    );

    const onPrevClick = React.useCallback(
        (event) => {
            event.preventDefault();
            setStep(() => Math.max(step - 1, 0));
        },
        [step, setStep]
    );

    return (
        <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
            <Stepper value={step} items={steps} />
            <Form
                initialValues={formState}
                onSubmitClick={onStepSubmit}
                render={(formRenderProps) => (
                    <div>
                        <FormElement>
                            {stepPages[step]}
                            <span style={{ marginTop: '40px' }} className={'k-form-separator'} />
                            <div
                                style={{ justifyContent: 'space-between', alignContent: 'center' }}
                                className={'k-form-buttons k-buttons-end'}
                            >
                                <span style={{ alignSelf: 'center' }}>Step {step + 1} of 3</span>
                                <div>
                                    {
                                        step !== 0 ? (
                                            <Button style={{ marginRight: '16px' }} onClick={onPrevClick}>
                                                Previous
                                            </Button>
                                        ) : undefined
                                    }
                                    <Button
                                        primary={true}
                                        disabled={!formRenderProps.allowSubmit}
                                        onClick={formRenderProps.onSubmit}
                                    >
                                        {isLastStep ? 'Submit' : 'Next'}
                                    </Button>
                                </div>
                            </div>
                        </FormElement>
                    </div>
                )}
            />
        </div>
    );
};

export default StepForm;