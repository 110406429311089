/*!

=========================================================
* Argon Design System React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-design-system-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-design-system-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
import axios from "axios";
import $ from "jquery";
import  { Redirect } from 'react-router-dom';
import background from "assets/img/brand/login.png";

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Container,
  Row,
  Col
} from "reactstrap";

// core components
// import DemoNavbar from "components/Navbars/DemoNavbar.js";
// import SimpleFooter from "components/Footers/SimpleFooter.js";

class Login extends React.Component {

  constructor(){
    super();
    this.state = {username:'',password:'',redirect:false};
  }
  componentDidMount() {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
    this.refs.main.scrollTop = 0;
  }

  login(){
    var username = this.state.username;
    var password = this.state.password;
    var username_pattern=/^[a-zA-Z0-9+_.-]+@[a-zA-Z0-9.-]+$/;
    if(!username.match(username_pattern) || username.length<4)
    {
      $('#message_business_user').html('Please enter a valid username').css('color', 'red');
    }
    return axios({
      method:"POST",
      url:"http://backend.pivotshot.com/authLogin/"+username,
      headers:{
        businesslogin:true
      },
      data:{
        password: password
      }
    }).catch((error)=>{
      console.log(error);
    }).then((res)=>{
      var response = res.data;
      console.log(response);
      if(response.code==403){
        alert('Please enter a valid username');
      }
      else if(response.code==402){
        alert("That's not the right password. Try again");
      }
      else if(response.code==400){
         alert("error");
      }
      else if(response.code==200){
        this.setState({redirect:true});
        localStorage.setItem("jwt-token",response.token);
      }
    });
  }

  render() {
    if(this.state.redirect){
      return <Redirect to="/landing-page"/>
    }
    return (
      <>
        {/* <DemoNavbar /> */}
        <main ref="main" style={{"backgroundImage":`url(${background})`,"background-position": "center",
  "background-repeat": "no-repeat","background-size": "cover"}}>
          <section className="section section-lg">
            {/* <div className="shape shape-style-1 bg-gradient-default">
              <span />
              <span />
              <span />
              <span />
              <span />
              <span />
              <span />
              <span />
            </div> */}
            <Container className="mt-5 mb-9">
              <Row className="">
                <Col lg="5">
                  <Card className="bg-secondary shadow border-0">
                    {/* <CardHeader className="bg-white pb-5">
                      <div className="text-muted text-center mb-3">
                        <small>Sign in with</small>
                      </div>
                      <div className="btn-wrapper text-center">
                        <Button
                          className="btn-neutral btn-icon"
                          color="default"
                          href="#"
                          onClick={e => e.preventDefault()}
                        >
                          <span className="btn-inner--icon mr-1">
                            <img
                              alt="..."
                              src={require("assets/img/icons/common/linkedin.svg")}
                            />
                          </span>
                          <span className="btn-inner--text">Linkedin</span>
                        </Button>
                        <Button
                          className="btn-neutral btn-icon ml-1"
                          color="default"
                          href="#"
                          onClick={e => e.preventDefault()}
                        >
                          <span className="btn-inner--icon mr-1">
                            <img
                              alt="..."
                              src={require("assets/img/icons/common/google.svg")}
                            />
                          </span>
                          <span className="btn-inner--text">Google</span>
                        </Button>
                      </div>
                    </CardHeader> */}
                    <CardBody className="px-lg-5 py-lg-5">
                      <div className="text-center text-muted mb-4">
                        <small>Sign in with credentials</small>
                      </div>
                      <Form role="form">
                        <FormGroup className="mb-3">
                          <InputGroup className="input-group-alternative">
                            <InputGroupAddon addonType="prepend">
                              <InputGroupText>
                                <i className="ni ni-email-83" />
                              </InputGroupText>
                            </InputGroupAddon>
                            <Input placeholder="Email" name="email" onChange={(e)=>{this.setState({username: e.target.value})}} type="email" />
                          </InputGroup>
                        </FormGroup>
                        <FormGroup>
                          <InputGroup className="input-group-alternative">
                            <InputGroupAddon addonType="prepend">
                              <InputGroupText>
                                <i className="ni ni-lock-circle-open" />
                              </InputGroupText>
                            </InputGroupAddon>
                            <Input
                              placeholder="Password"
                              type="password"
                              name="password"
                              autoComplete="off"
                              onChange={(e)=>{this.setState({password: e.target.value})}}
                            />
                          </InputGroup>
                        </FormGroup>
                        {/* <div className="custom-control custom-control-alternative custom-checkbox">
                          <input
                            className="custom-control-input"
                            id=" customCheckLogin"
                            type="checkbox"
                          />
                          <label
                            className="custom-control-label"
                            htmlFor=" customCheckLogin"
                          >
                            <span>Remember me</span>
                          </label>
                        </div> */}
                        <div className="text-center">
                          <Button
                            className="my-4"
                            color="primary"
                            onClick = {this.login.bind(this)}
                          >
                            Sign in
                          </Button>
                        </div>
                      </Form>
                    </CardBody>
                  </Card>
                  <Row className="mt-3">
                    <Col xs="6">
                      <a
                        className="text-light"
                        href="#"
                        onClick={e => e.preventDefault()}
                      >
                        <small>Forgot password?</small>
                      </a>
                    </Col>
                    <Col className="text-right" xs="6">
                      <a
                        className="text-light"
                        href="/register"
                      >
                        <small>Create new account</small>
                      </a>
                    </Col>
                  </Row>
                </Col>
              </Row>
                {/* <Col style={{"margin":"0 auto"}} className="mt-3" lg="10">
                  <img
                    alt="..."
                    width="100%"
                    src={require("assets/img/brand/login.png")}
                  />
                </Col> */}
            </Container>
          </section>
        </main>
        {/* <SimpleFooter /> */}
      </>
    );
  }
}

export default Login;
