import * as React from 'react';

import { Field } from '@progress/kendo-react-form';

import {
    FormInput, FormAutoComplete, FormRadioGroup,FormDropDownList,
    FormTextArea,FormFloatingNumericTextBox
} from './form-components.jsx';

import {
    nameValidator, requiredValidator
} from './validators.jsx'

import {
    countries, genders
} from './data.jsx'

export const ProjectDetails = (
    <div style={{ width: 480 }}>
        {/* <Field
            key={'fullName'}
            id={'fullName'}
            name={'fullName'}
            label={'Full Name'}
            component={FormInput}
            validator={nameValidator}
        />
        <Field
            key={'countryselected'}
            id={'countryselected'}
            name={'countryselected'}
            label={'Country'}
            hint={'Hint: Only Eroupean countries'}
            component={FormAutoComplete}
            data={countries}
            validator={requiredValidator}
        />
        <Field
            key={'gender'}
            id={'gender'}
            name={'gender'}
            label={'Gender'}
            layout={'horizontal'}
            component={FormRadioGroup}
            data={genders}
            validator={requiredValidator}
        /> */}
        <Field
            key={'projectLeader'}
            id={'projectLeader'}
            name={'projectLeader'}
            label={'Project Leader'}
            component={FormDropDownList}
            data={["Leader 1","Leader 2","Leader 3"]}
            defaultValue={"Leader 1"}
        />
        <Field
            key={'projectMember'}
            id={'projectMember'}
            name={'projectMember'}
            label={'Project Members'}
            component={FormDropDownList}
            data={["Member 1","Member 2","Member 3"]}
            defaultValue={"Member 1"}
        />
        <Field
            key={'sponsorName'}
            id={'sponsorName'}
            name={'sponsorName'}
            label={'Sponsor Name'}
            component={FormInput}
            validator={nameValidator}
        />
        <Field
            key={'projectBudget'}
            id={'projectBudget'}
            name={'projectBudget'}
            label={'Project Budget'}
            component={FormFloatingNumericTextBox}
            validator={requiredValidator}
        />
        {/* <Field
            key={'execStatusSummary'}
            id={'execStatusSummary'}
            name={'execStatusSummary'}
            label={'Executive Status Summary'}
            validator={requiredValidator}
            component={FormFloatingNumericTextBox}
        /> */}
        {/* <Field
            key={'execStatusSummary'}
            id={'execStatusSummary'}
            name={'execStatusSummary'}
            label={'Executive Status Summary'}
            validator={requiredValidator}
            component={FormTextArea}
        />
        <Field
            key={'keyAccomplishments'}
            id={'keyAccomplishments'}
            name={'keyAccomplishments'}
            label={'Key Accomplishments in Past Week'}
            validator={requiredValidator}
            component={FormTextArea}
        />
        <Field
            key={'keyTasks'}
            id={'keyTasks'}
            name={'keyTasks'}
            label={'Key Tasks in Next 2 Weeks'}
            
            component={FormTextArea}
        /> */}
    </div>
);