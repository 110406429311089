import React from "react";
import axios from "axios";
import { Button} from "reactstrap";
import DateEditor from "react-tabulator/lib/editors/DateEditor";
import MultiValueFormatter from "react-tabulator/lib/formatters/MultiValueFormatter";
// import MultiSelectEditor from "react-tabulator/lib/editors/MultiSelectEditor";

import "react-tabulator/lib/styles.css"; // default theme
import "react-tabulator/css/bootstrap/tabulator_bootstrap.min.css"; // use Theme(s)

import { ReactTabulator, reactFormatter } from "react-tabulator"; 

function SimpleButton(props) {
  const rowData = props.cell._cell.row.data;
  const cellValue = props.cell._cell.value || "Edit | Show";
  return <button onClick={() => alert(rowData.name)}>{cellValue}</button>;
}



class EditableTableTabulator extends React.Component {
  state = {
    exec_status_reports: [],
    length:0,
    personnel:[],
    editableColumns:[]
  };
  constructor(props){
    super(props);
  }
  ref = null;

  componentDidMount(){
    axios({
      method:"GET",
      url:"http://backend.pivotshot.com/getExecStatusReports",
      headers:{
        jwttoken: localStorage.getItem("jwt-token")
      },
    }).then((res)=>{
      var response = res.data;
      console.log(response);
      if(response.exec_status_reports.length>0){
        // alert(response.ri.length);
        this.setState({length:response.exec_status_reports.length});
        this.setState({exec_status_reports:response.exec_status_reports});
        this.setState({personnel:response.personnel});
        this.setState({editableColumns: [{title: "Report ID",field:"exec_status_id"},
        {
          title: "Key Accomplishments",
          field: "key_accomplishments",
          width: 150,
          editor: "input",
          // headerFilter: "input"
        },
        {
          title: "Executive Status Summary",
          field: "execstatus_summary",
          width: 150,
          editor: "input",
          // headerFilter: "input"
        },
        {
          title: "Key Tasks",
          field: "key_tasks",
          width: 150,
          editor: "input",
          // headerFilter: "input"
        },
        {
          title: "Project Lead",
          field: "proj_lead",
          editor: "select",
          editorParams: {
            allowEmpty: true,
            showListOnEmpty: true,
            values: this.state.personnel
          },
          headerFilter: "select",
          headerFilterParams: { values: this.state.personnel }
        },
        {
          title: "Project Manager",
          field: "proj_manager",
          editor: "select",
          editorParams: {
            allowEmpty: true,
            showListOnEmpty: true,
            values: this.state.personnel
          },
          headerFilter: "select",
          headerFilterParams: { values: this.state.personnel }
        },
        {
          title: "Week Ending",
          field: "week_ending",
          sorter: "date",
          editor: DateEditor,
          editorParams: { format: "MM/DD/YYYY" }
        },
        {
          title: "Project Ending",
          field: "proj_end",
          sorter: "date",
          editor: DateEditor,
          editorParams: { format: "MM/DD/YYYY" }
        },
        {
          title: "Created By",
          field: "created_by",
          width: 150,
          // headerFilter: "input"
        },
        {
          title: "Delete Project",
          field: "delete_proj",
          formatter: "buttonCross",
          align:"center",
          cellClick: (e,cell)=>{
            cell.getRow().delete();
          }
        }]})
      }
    });
  }

  cellEdit = (cell,e)=>{
    var exec_status_report = cell.getRow().getData();
    console.log(exec_status_report);
    axios({
      method:"POST",
      url:"http://backend.pivotshot.com/updateExecStatusReports",
      headers:{
        jwttoken: localStorage.getItem("jwt-token")
      },
      data:{
        exec_status_report:exec_status_report
      }
    }).then((res)=>{
      var response = res.data;
      console.log(response);
    });
  }

  render() {
    const options = {
      // height: 150,
      movableRows: true,
      movableColumns: true
    };
    return (
      <div>
        {/* <ReactTabulator
          ref={(ref) => (this.ref = ref)}
          columns={this.columns}
          data={data}
          rowClick={this.rowClick}
          options={options}
          data-custom-attr="test-custom-attribute"
          className="custom-css-class"
        /> */}
        {/* <i>
          Selected Name: <strong>{this.state.selectedName}</strong>
        </i> */}

        {/* <h3>
          Asynchronous data: (e.g. fetch) -{" "}
          <button onClick={this.setData}>Set Data</button>
          <button onClick={this.clearData}>Clear</button>
        </h3> */}
        {/* <ReactTabulator columns={this.columns} data={this.state.data} /> */}

        {/* <h3>Editable Table</h3> */}
        {/* <Button
          className="btn-icon btn-3 ml-1 mb-2"
          color="primary"
          type="button"
          onClick={this.onAddRow}
        >
          <span className="btn-inner--icon mr-1">
            <i className="fa fa-plus" />
          </span>
          <span className="btn-inner--text">Add Row</span>
      </Button> */}
        <ReactTabulator
          ref={(ref) => (this.ref = ref)}
          columns={this.state.editableColumns}
          data={this.state.exec_status_reports}
          cellEdited={this.cellEdit}
          // rowClick={this.rowClick}
          options={options}
          footerElement={<span>Footer</span>}
        />
      </div>
    );
  }
}

export default EditableTableTabulator;
