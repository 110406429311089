/*!

=========================================================
* Argon Design System React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-design-system-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-design-system-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";
import { GuardProvider, GuardedRoute } from 'react-router-guards';

import "assets/vendor/nucleo/css/nucleo.css";
import "assets/vendor/font-awesome/css/font-awesome.min.css";
import "assets/css/argon-design-system-react.css";

import Index from "views/Index.js";
import Landing from "views/examples/Landing.js";
import LandingBlack from "views/examples/Landing-Black.js";
import Login from "views/examples/Login.js";
import Profile from "views/examples/Profile.js";
import Register from "views/examples/Register.js";
import Planner from "views/examples/Planner.js";
import PlannerUpdate from "views/examples/Planner-update.js";
import AllPlanners from "views/examples/Planner-all.js"
import ExecutiveStatus from "views/examples/Executive-status.js";
import ExecutiveStatusKeyRisks from "views/examples/Executive-Status-Key-Risks.js";
import ExecutiveStatusPrevReports from "views/examples/Executive-Status-Prev-Reports.js";
import RevenueTracker from "views/examples/Revenue-Tracker.js";
import RevenueTrackerLeads from "views/examples/Revenue-Tracker-Leads.js";
import RevenueTrackerCampaign from "views/examples/Revenue-Tracker-Campaign.js";

const requireLogin = (to, from, next) => {
  if(to.location.pathname==='/register')
  {
    next();
  }
  if (!localStorage.hasOwnProperty("jwt-token")) {
    next.redirect('/');
  } else {
    next();
  }
};

ReactDOM.render(
  <BrowserRouter>
  <GuardProvider guards={[requireLogin]}>
    <Switch>
      <GuardedRoute  path="/" exact render={props => <Login {...props} />} />
      <GuardedRoute 
        path="/landing-page"
        exact
        render={props => <Landing {...props} />}
      />
      <GuardedRoute 
        path="/landing-page-black"
        exact
        render={props => <LandingBlack {...props} />}
      />
      <GuardedRoute 
        path="/main"
        exact
        render={props => <Index {...props} />}
      />
      <GuardedRoute  path="/login" exact render={props => <Login {...props} />} />
      <GuardedRoute 
        path="/profile"
        exact
        render={props => <Profile {...props} />}
      />
      <GuardedRoute 
        path="/register"
        exact
        render={props => <Register {...props} />}
      />
      <GuardedRoute 
        path="/planner"
        exact
        render={props => <Planner {...props} />}
      />
      <GuardedRoute  path="/create-planner" exact render={props => <Planner {...props} />} />
      <GuardedRoute  path="/update-planner" exact render={props => <PlannerUpdate {...props} />} />
      <GuardedRoute  path="/all-planner" exact render={props => <AllPlanners {...props} />} />
      <GuardedRoute 
        path="/executive-status"
        exact
        render={props => <ExecutiveStatus {...props} />}
      />
      <GuardedRoute  path="/create-report" exact render={props => <ExecutiveStatus {...props} />} />
      <GuardedRoute  path="/key-risks" exact render={props => <ExecutiveStatusKeyRisks {...props} />} />
      <GuardedRoute  path="/prev-reports" exact render={props => <ExecutiveStatusPrevReports {...props} />} />

      <GuardedRoute 
        path="/revenue-tracker"
        exact
        render={props => <RevenueTracker {...props} />}
      />
      <GuardedRoute  path="/contacts" exact render={props => <RevenueTracker {...props} />} />
      <GuardedRoute  path="/leads" exact render={props => <RevenueTrackerLeads {...props} />} />
      <GuardedRoute  path="/campaign" exact render={props => <RevenueTrackerCampaign {...props} />} />
      <Redirect to="/" />
    </Switch>
  </GuardProvider>
  </BrowserRouter>,
  document.getElementById("root")
);
